<template>
    <div>
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>      
            </div>
        </base-header>
        <div class="container-fluid mt--6">
            <div class="card mb-4">
                <div class="card-header mt--4 mb--4">
                    <div class="row">
                        <div class="col">
                            <h3 class="mb-0">{{ tt('notification_master') }}</h3>
                        </div>
                        <div class="col-6">
                            <base-input input-classes="form-control-sm text-center" v-model="notification.search" :placeholder="tt('search_notification_master')"/>
                        </div>
                        <div class="col text-right">
                            <base-button size="sm" type="default" @click="create">{{ tt('add_new') }}</base-button>
                        </div>
                    </div>
                </div>
                <div>
                    <el-table height="350px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.data">
                        <el-table-column :label="tt('type')" :prop="tt('type')" min-width="120px" sortable>
                            <template v-slot="{row}">
                                {{row.type}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('code')" :prop="tt('code')" min-width="120px" sortable>
                            <template v-slot="{row}">
                                {{row.code}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('description')" :prop="tt('description')" min-width="160px" sortable>
                            <template v-slot="{row}">
                                {{row.description}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('subject')" :prop="tt('subject')" min-width="150px" sortable>
                            <template v-slot="{row}">
                                {{row.subject}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('message')" :prop="tt('message')" min-width="150px" sortable>
                            <template v-slot="{row}">
                                {{row.message}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('url')" :prop="tt('url')" min-width="100px" sortable>
                            <template v-slot="{row}">
                                {{row.url}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('send_mail')" :prop="tt('send_mail')" min-width="150px" sortable>
                            <template v-slot="{row}">
                                {{row.send_mail}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('recipient')" :prop="tt('recipient')" min-width="150px" sortable>
                            <template v-slot="{row}">
                                {{row.recipient}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="action" width="100">
                            <template v-slot="{row}">
                                <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true">
                                    <span class="btn btn-sm btn-icon-only text-light">
                                        <i class="fas fa-ellipsis-v mt-2"></i>
                                    </span>
                                    <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                        <el-dropdown-item :command="{action:'edit', data:row.id}">Edit</el-dropdown-item>
                                        <el-dropdown-item :command="{action:'remove', data:row.id}">Delete</el-dropdown-item>                                        
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="card-footer">  
                    <div class="row">                                  
                        <div class="col text-right">
                            {{ tt('page_info', {size: table.total, total: table.total}) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal :show.sync="form.show">
            <template slot="header">
                <h5 class="modal-title">{{form.title}}</h5>
            </template>
            <div>
                <label class="form-control-label">{{ tt('type') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('type')" rules="required">
                    <el-select class="select-danger" v-model="notification.type" placeholder="Choose Type">
                        <el-option class="select-danger" value="01" label="Dynamic" key="Dynamic"></el-option>
                        <el-option class="select-danger" value="01" label="Static" key="Static"></el-option>
                    </el-select>
                </base-input>

                <label class="form-control-label">{{ tt('code') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('code')" :placeholder="tt('code')" v-model="notification.code" rules="required"></base-input>

                <label class="form-control-label">{{ tt('description') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('description')" :placeholder="tt('description')" v-model="notification.description" rules="required"></base-input>

                <label class="form-control-label">{{ tt('subject') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('subject')" :placeholder="tt('subject')" v-model="notification.subject" rules="required"></base-input>

                <label class="form-control-label">{{ tt('message') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('message')" :placeholder="tt('message')" v-model="notification.message" rules="required"></base-input>

                <label class="form-control-label">{{ tt('url') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('url')" :placeholder="tt('url')" v-model="notification.url" rules="required"></base-input>

                <label class="form-control-label">{{ tt('send_mail') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('send_mail')" :placeholder="tt('send_mail')" v-model="notification.send_mail" rules="required"></base-input>

                <label class="form-control-label">{{ tt('recipient') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('recipient')" :placeholder="tt('recipient')" v-model="notification.recipient" rules="required"></base-input>
            </div>
            <template slot="footer">
                <base-button type="secondary" @click="form.show = false">{{ tt('reset') }}</base-button>
                <base-button type="primary">
                    <span v-if="form.add">{{ tt('add') }}</span>
                    <span v-else>{{ tt('edit') }}</span>
                </base-button>
            </template>
        </modal>
    </div>
</template>
<script>
    export default {        
        data() {
            return {                
                form: {
                    add: true,
                    title: "Add Notification Master",
                    show: false
                }, 
                table: {
                    data: [
                        {
                            id: 1,
                            type: 2, 
                            code: 89,
                            description: 'des',
                            subject: "sub", 
                            message: "mes", 
                            url: "url", 
                            send_mail: "send", 
                            recipient: "rec", 
                        },
                        {
                            id: 1,
                            type: 1, 
                            code: 12,
                            description: 'des',
                            subject: "sub", 
                            message: "mes", 
                            url: "url", 
                            send_mail: "send", 
                            recipient: "rec", 
                        },
                    ]
                },        
                notification: {
                    
                }  
            }
        },
        methods: {
            handleTableAction(command) {
                switch (command.action) {
                    case 'edit':
                            this.edit(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    default:
                        break;
                }
            },
            create() {
                this.form.add = true;
                this.form.title = "Add Notification Master";
                this.form.show = true;
            },
            edit(id) {
                this.form.add = false;
                this.form.title = "Edit Notification Master";
                this.form.show = true;
            },
            remove(id) {
                this.confirmDialog("Are you sure to delete this data?").then((result) => {
                    if (result.value) {
                        this.alertDialog('success', 'Successed delete data');
                    } else {
                        this.alertDialog('error', 'Failed delete data');
                    }
                })
            },
        }   
    };
</script>
<style></style>
